import classNames from "classnames";
import React from "react";
import type { Header } from "../../types";

export interface HeroProps {
	header: Header;
}

export const Hero: React.FC<HeroProps> = ({ header }) => {
	const imageClasses = classNames(
		"h-125 sm:h-150 lg:h-175 top-0 left-0 absolute w-full object-cover object-center brightness-60",
	);
	return (
		<main className="h-120 sm:h-150 lg:h-175 flex items-center  mx-auto max-w-7xl px-8">
			<div
				className="clip-path   h-120 sm:h-150 lg:h-175 top-0 left-0 absolute w-full"
				style={{ zIndex: -1 }}
			>
				<div className={`${imageClasses} bg-stone-700`} />
				<img src="/hero-static.jpg" alt="" className={`${imageClasses} `} />
				<video
					muted
					loop
					autoPlay
					controls={false}
					playsInline
					className={`${imageClasses} `}
				>
					<source
						src="/output.mp4"
						type="video/mp4"
						media="all and (max-width: 480px)"
					/>
					<source src="/output-fast.mp4" type="video/mp4" />
				</video>
			</div>
			<div className="flex-1 w-full flex flex-col gap-10 md:gap-8 lg:gap-12 -mt-3 sm:mt-0">
				<div className="flex flex-1 w-full flex-col  justify-center  gap-6 sm:gap-8 lg:gap-10">
					<p
						style={{ animationDelay: "50ms" }}
						className="fadeInUp  text:lg sm:text-xl md:text-2xl max-w-md  text-white"
					>
						{header.description}
					</p>
					<div>
						<h1>
							<span
								style={{ animationDelay: "600ms" }}
								className="block fadeInUp font-serif font-bold text-3_5xl 2xs:text-4xl xs:text-5xl sm:text-5xl lg:text-7xl leading-tight   text-slate-100"
							>
								Event Solutions.
							</span>
							<span
								style={{ animationDelay: "1000ms" }}
								className="block fadeInUp font-serif font-bold text-3_5xl 2xs:text-4xl xs:text-5xl sm:text-5xl lg:text-7xl  leading-tight  text-teal-400"
							>
								Innovative Thinking.
							</span>

							<span
								style={{ animationDelay: "1400ms" }}
								className="block fadeInUp font-serif font-bold text-3_5xl 2xs:text-4xl xs:text-5xl sm:text-5xl lg:text-7xl leading-tight  text-slate-100"
							>
								Profound Experiences.
							</span>
						</h1>
					</div>
				</div>
				<div>
					<a href="/contact" id="contact-us">
						<button
							type="button"
							style={{ animationDelay: "1800ms" }}
							className="hover:-translate-y-1 translate-y-0 fadeInUp transition-all rounded-md bg-teal-500  w-full sm:w-auto px-6 py-2 sm:px-8 sm:py-3  tracking-wide text-lg md:text-xl font-medium text-white shadow-sm hover:bg-teal-600 border border-teal-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 "
						>
							Contact Us
						</button>
					</a>
				</div>
			</div>
		</main>
	);
};
